// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Alert/Alert.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Alert/Alert.tsx");
  import.meta.hot.lastModified = "1721816750000";
}
// REMIX HMR END

import React from 'react';
import { Alert as BaseAlert, Typography } from '@mui/material';
export default function Alert(props) {
  return <BaseAlert {...props}>
      <Typography variant="body1" color="white" className="message-alert h-full w-full flex items-center">
        {props.children}
      </Typography>
    </BaseAlert>;
}
_c = Alert;
var _c;
$RefreshReg$(_c, "Alert");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;